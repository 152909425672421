<template lang="html" src="./dispatchValidation.template.vue"></template>

<style lang="scss" src="./dispatchValidation.scss"></style>

<script>

const i18nData = require('./dispatchValidation.i18n');

export default {
  name: 'DispatchValidation',
  components: {
  },
  props: {
    dispatch: {
      type: Object,
      required: true,
    },
    supplier: {
      type: Object,
      required: true
    },
    model: {
      type: Number,
      default: null
    }
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      displayMode: 0
    };
  },
  created() {
  },
  methods: {
  }
};
</script>
