<template lang="html" src="./pageQuote.template.vue"></template>

<style lang="scss" src="./pageQuote.scss"></style>

<script>
import {
  ApiErrorParser,
  BeelseQuote,
  BeelseSupplierQuote,
  AddCustomLine,
  ModifyCustomLine,
  ModifyItemLine,
  ModifyDelivery
} from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageQuote.i18n');

import DispatchValidation from '../../components/dispatchValidation/DispatchValidation';
import QuoteValidation from '../../components/quoteValidation/QuoteValidation';

export default {
  name: 'PageQuote',
  components: {
    AddCustomLine,
    BeelseQuote,
    BeelseSupplierQuote,
    ModifyCustomLine,
    ModifyItemLine,
    ModifyDelivery,
    DispatchValidation,
    QuoteValidation
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      loadingFormDeliveryPrice: false,
      quote: null,
      quoteInternalNote: null,
      pdfData: null,
      editNote: false,
      disabled: true,
      loading: false,
      loadingQuotePDF: false,
      suppliers: {},
      loadingSyncQB: false,
      typeOfEditPrice: '',
      partUUIDEditPrice: null,
      supplierUUIDEditPrice: null,
      dispatchLoaded: false,
      addQuoteCustomLineDialog: false,
      modifyQuoteCustomLineDialog: false,
      quoteCustomLineToModify: null,
      addDispatchCustomLineDialog: false,
      modifyDispatchCustomLineDialog: false,
      dispatchCustomLineToModify: null,
      modifyDispatchDeliveryDialog: false,
      dispatchDeliveryToModify: null,
      modifyDispatchItemDialog: false,
      dispatchItemToModify: null,
      customLineToModify: null,
      currentDispatchSupplier: null,
      expandedDispatches: {}
    };
  },
  computed: {
  },
  created() {
    this.getQuote();
  },
  methods: {
    getQuote() {
      this.disabled = true;
      this.pdfData = null;
      this.quote = null;
      /**
       * GET QUOTE
       */
      this.$apiInstance.getAdminQuote(this.$route.params.quoteUUID).then(
        (quoteData) => {
          this.quote = quoteData;
          this.quoteInternalNote = this.quote.internalNote;
          for(const dispatch of this.quote.dispatch) {
            if(!this.expandedDispatches[dispatch.supplier]) {
              this.$set(this.expandedDispatches, dispatch.supplier, null);
            }
          }
          this.loadingDispatch();
        },
        (error) => {
          /**
           * ERROR GET QUOTE
           *
           * Component BeelseNotifications used
           */
          this.$notification.notify('DANGER', ApiErrorParser.parse(error));
        }
      );
    },
    loadingDispatch() {
      this.dispatchLoaded = this.quote.dispatch.length === 0;
      this.suppliers = {};
      this.quote.dispatch.forEach((dispatch) => {
        this.$apiInstance.getSupplier(dispatch.supplier).then((supplier) => {
          this.suppliers[dispatch.supplier] = supplier;
          this.dispatchLoaded = this.dispatchLoaded || (this.quote.dispatch.length === Object.keys(this.suppliers).length);
        });
      });
    },
    quoteForSupplierQuote(supplierUUID) {
      const quote = {...this.quote};
      quote.dispatch = quote.dispatch.find(d => {return d.supplier === supplierUUID;});
      return quote;
    },
    getQuotePDF() {
      /**
       * GET QUOTE PDF
       */
      if(!this.pdfData) {
        this.loadingQuotePDF = true;
        this.$apiInstance
          .getBrandQuotePDF(this.quote.brand, this.quote._id, {lang: this.$userLocale})
          .then(
            (pdf) => {
              this.pdfData = pdf.data;
              this.$refs.quotePDFRef.href = `data:application/pdf;base64,${this.pdfData}`;
              setTimeout(() => {
                this.$refs.quotePDFRef.click();
              }, 250);
            },
            (error) => {
              /**
               * ERROR GET QUOTE PDF
               *
               * Component BeelseNotifications used
               */
              this.$notification.notify('DANGER', ApiErrorParser.parse(error));
            }
          )
          .finally(() => {
            this.loadingQuotePDF = false;
          });
      } else {
        this.$refs.quotePDFRef.click();
      }
    },
    openAddQuoteCustomLineDialog() {
      this.addQuoteCustomLineDialog = true;
    },
    closeAddQuoteCustomLineDialog() {
      this.addQuoteCustomLineDialog = false;
    },
    openAddDispatchCustomLineDialog(supplierUUID) {
      this.currentDispatchSupplier = supplierUUID;
      this.addDispatchCustomLineDialog = true;
    },
    closeAddDispatchCustomLineDialog() {
      this.currentDispatchSupplier = null;
      this.addDispatchCustomLineDialog = false;
    },
    openModifyQuoteCustomLineDialog(customLineUUID) {
      this.customLineToModify = this.quote.customLines.find(cL =>  {return cL._id === customLineUUID;});
      this.modifyQuoteCustomLineDialog = true;
    },
    closeModifyQuoteCustomLineDialog() {
      this.modifyQuoteCustomLineDialog = false;
    },
    openModifyDispatchCustomLineDialog(supplierUUID, customLineUUID) {
      const dispatch = this.quote.dispatch.find(d => {return d.supplier === supplierUUID;});
      this.currentDispatchSupplier = supplierUUID;
      this.customLineToModify = dispatch.customLines.find(cL => {return cL._id === customLineUUID;});
      this.modifyDispatchCustomLineDialog = true;
    },
    closeModifyDispatchCustomLineDialog() {
      this.modifyDispatchCustomLineDialog = false;
    },
    openModifyDispatchDeliveryDialog(supplierUUID) {
      const dispatch = this.quote.dispatch.find(d => {return d.supplier === supplierUUID;});
      this.dispatchDeliveryToModify = dispatch;
      this.modifyDispatchDeliveryDialog = true;
    },
    closeModifyDispatchDeliveryDialog() {
      this.dispatchDeliveryToModify = null;
      this.modifyDispatchDeliveryDialog = false;
    },
    openModifyDispatchItemDialog(supplierUUID, partUUID) {
      const dispatch = this.quote.dispatch.find(d => {return d.supplier === supplierUUID;});
      this.currentDispatchSupplier = supplierUUID;
      this.dispatchItemToModify = dispatch.items.find(i => {return i.part._id.toString() === partUUID;});
      this.modifyDispatchItemDialog = true;
    },
    closeModifyDispatchItemDialog() {
      this.dispatchItemToModify = null;
      this.modifyDispatchItemDialog = false;
    },
    /**
     * MODIFY QUOTE INTERNAL NOTE
     */
    modifyEditNote() {
      const modifyQuoteInternalNoteBody = new this.$BcmModel.ModifyQuoteInternalNoteBody(
        this.quoteInternalNote
      );
      this.$apiInstance
        .modifyQuoteInternalNote(
          this.$route.params.quoteUUID,
          modifyQuoteInternalNoteBody
        )
        .then(
          (quoteInternalNoteData) => {
            this.editNote = false;
            this.quoteInternalNote = quoteInternalNoteData;
            this.$notification.notify('SUCCESS',this.$t('InternalNoteUpdated'));
          },
          (error) => {
            /**
             * ERROR MODIFY QUOTE INTERNAL NOTE
             *
             * Component BeelseNotifications used
             */
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    /**
     * QUOTE INTERNAL NOTE EDITOR ACTION
     */
    cancelEditNote() {
      this.editNote = false;
      this.quoteInternalNote = this.quote.intenalNote;
    },
    modifyQuoteDispatchItemPrices(partUUID, modifiedItem) {
      const modifyQuoteDispatchItemPricesBody = new this.$BcmModel.ModifyQuoteDispatchItemPricesBody(
        {
          price: Number(modifiedItem.purchase.price),
          vatPercentage: Number(modifiedItem.purchase.vatPercentage)
        },
        {
          price: Number(modifiedItem.selling.price),
          vatPercentage: Number(modifiedItem.selling.vatPercentage)
        }
      );

      this.$apiInstance.modifyQuoteDispatchItemPrices(
        this.$route.params.quoteUUID,
        this.currentDispatchSupplier,
        partUUID,
        modifyQuoteDispatchItemPricesBody
      ).then(data => {
        this.$notification.notify('SUCCESS', this.$t('ItemPricesHaveBeenUpdated'));
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      })
        .finally(() => {
          this.closeModifyDispatchItemDialog();
          this.getQuote();
        });
    },
    modifyQuoteDispatchDelivery(supplierUUID, modifiedDelivery) {
      const modifyQuoteDispatchDeliveryPurchasePriceBody = new this.$BcmModel.ModifyDispatchDeliveryPriceBody(
        Number(modifiedDelivery.purchase.price),
        Number(modifiedDelivery.purchase.vatPercentage)
      );

      const modifyQuoteDispatchDeliverySellingPriceBody = new this.$BcmModel.ModifyDispatchDeliveryPriceBody(
        Number(modifiedDelivery.selling.price),
        Number(modifiedDelivery.selling.vatPercentage)
      );

      if(this.quote.dispatch.find(d => {return d.supplier === supplierUUID;})?.deliveryPricingType === 'SUPPLIER') {
        this.$apiInstance.modifyQuoteDispatchPurchaseDeliveryPrice(
          this.$route.params.quoteUUID,
          supplierUUID,
          modifyQuoteDispatchDeliveryPurchasePriceBody
        ).then(data => {
          this.$apiInstance.modifyQuoteDispatchSellingDeliveryPrice(
            this.$route.params.quoteUUID,
            supplierUUID,
            modifyQuoteDispatchDeliverySellingPriceBody
          ).then(data => {
            this.$notification.notify('SUCCESS', this.$t('DeliveryPricesHaveBeenUpdated'));
          }, (error) => {
            this.$notification.notify('ERROR', ApiErrorParser.parse(error));
          }).finally(() => {
            this.getQuote();
            this.closeModifyDispatchDeliveryDialog();
          });
        }, (error) => {
          this.$notification.notify('ERROR', ApiErrorParser.parse(error));
          this.getQuote();
          this.closeModifyDispatchDeliveryDialog();
        });
      } else {
        this.$apiInstance.modifyQuoteDispatchSellingDeliveryPrice(
          this.$route.params.quoteUUID,
          supplierUUID,
          modifyQuoteDispatchDeliverySellingPriceBody
        ).then(data => {
          this.$notification.notify('SUCCESS', this.$t('DeliveryPricesHaveBeenUpdated'));
        }, (error) => {
          this.$notification.notify('ERROR', ApiErrorParser.parse(error));
        }).finally(() => {
          this.getQuote();
          this.closeModifyDispatchDeliveryDialog();
        });
      }
    },
    modifyQuoteDispatchPublicNote(supplierUUID, publicNote) {
      const modifyQuoteDispatchPublicNote = new this.$BcmModel.ModifyDispatchPublicNoteBody(
        publicNote
      );

      this.$apiInstance.modifyQuoteDispatchPublicNote(
        this.$route.params.quoteUUID,
        supplierUUID,
        modifyQuoteDispatchPublicNote
      )
        .then(
          () => {
            this.$notification.notify('SUCCESS',this.$t('UpdatedDispatchPublicNote'));
          },
          (error) => {
            this.$notification.notify('DANGER', ApiErrorParser.parse(error));
          }
        ).finally(() => {
          this.getQuote();
        });
    },
    modifyQuoteDispatchDeliveryNote(supplierUUID, deliveryNote) {
      const modifyQuoteDispatchDeliveryNote = new this.$BcmModel.ModifyDispatchDeliveryNoteBody(
        deliveryNote
      );

      this.$apiInstance.modifyQuoteDispatchDeliveryNote(
        this.$route.params.quoteUUID,
        supplierUUID,
        modifyQuoteDispatchDeliveryNote
      )
        .then(
          () => {
            this.$notification.notify('SUCCESS',this.$t('UpdatedDispatchDeliveryNote'));
          },
          (error) => {
            this.$notification.notify('DANGER', ApiErrorParser.parse(error));
          }
        ).finally(() => {
          this.getQuote();
        });
    },
    modifyQuoteStatus(status) {
      const modifyAdminQuoteStatusBody = new this.$BcmModel.ModifyAdminQuoteStatusBody(status);
      
      this.$apiInstance.modifyAdminQuoteStatus(
        this.$route.params.quoteUUID,
        modifyAdminQuoteStatusBody
      ).then(data => {
        if(data.status === 'LOCKED') {
          this.$notification.notify('SUCCESS',this.$t('QuoteLockedSuccess'));
        } else if(data.status === 'READY') {
          this.$notification.notify('SUCCESS',this.$t('QuoteSubmittedSuccess'));
        }
      }, (error) => {
        this.$notification.notify('DANGER', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getQuote();
      });
    },
    modifyQuoteExpirationDate(expirationDate) {
      const modifyQuoteExpirationDateBody = new this.$BcmModel.ModifyQuoteExpirationDateBody(
        expirationDate
      );

      this.$apiInstance.modifyQuoteExpirationDate(
        this.$route.params.quoteUUID,
        modifyQuoteExpirationDateBody
      ).then(data => {
        this.$notification.notify('SUCCESS', this.$t('QuoteExpirationDateHasBeenModified'));
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getQuote();
      });
    },
    modifyQuotePublicNote(publicNote) {
      const modifyQuotePublicNote = new this.$BcmModel.ModifyQuotePublicNoteBody(
        publicNote
      );

      this.$apiInstance.modifyQuotePublicNote(
        this.$route.params.quoteUUID,
        modifyQuotePublicNote
      )
        .then(
          () => {
            this.$notification.notify('SUCCESS',this.$t('UpdatedQuotePublicNote'));
          },
          (error) => {
            this.$notification.notify('DANGER', ApiErrorParser.parse(error));
          }
        ).finally(() => {
          this.getQuote();
        });
    },
    modifyQuoteDispatchDeliveryPricingType(supplierUUID, type) {
      const modifyDispatchDeliveryPricingTypeBody = new this.$BcmModel.ModifyDispatchDeliveryPricingTypeBody(type);
      this.$apiInstance.modifyQuoteDispatchDeliveryPricingType(
        this.$route.params.quoteUUID,
        supplierUUID,
        modifyDispatchDeliveryPricingTypeBody
      ).then(() => {
        this.$notification.notify('SUCCESS',this.$t('UpdatedDeliveryPricingType'));
      }, (error) => {
        this.$notification.notify('DANGER', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getQuote();
      });
    },
    addQuoteCustomLine(newCustomLine) {
      const addQuoteCustomLineBody = new this.$BcmModel.AddQuoteCustomLineBody(
        newCustomLine.description,
        Number(newCustomLine.quantity),
        newCustomLine.optional,
        newCustomLine.selected,
        {
          price: Number(newCustomLine.selling.price),
          vatPercentage: Number(newCustomLine.selling.vatPercentage)
        }
      );

      this.$apiInstance.addQuoteCustomLine(
        this.$route.params.quoteUUID,
        addQuoteCustomLineBody
      ).then((data) => {
        this.$notification.notify('SUCCESS',this.$t('AddedLineToQuote'));
        this.addQuoteCustomLineDialog = false;
      }, (error) => {
        this.$notification.notify('DANGER', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getQuote();
      });
    },
    addDispatchCustomLine(newCustomLine) {
      const addQuoteDispatchCustomLineBody = new this.$BcmModel.AddQuoteDispatchCustomLineBody(
        newCustomLine.description,
        Number(newCustomLine.quantity),
        newCustomLine.optional,
        newCustomLine.selected,
        {
          price: Number(newCustomLine.purchase.price),
          vatPercentage: Number(newCustomLine.purchase.vatPercentage)
        },
        {
          price: Number(newCustomLine.selling.price),
          vatPercentage: Number(newCustomLine.selling.vatPercentage)
        }
      );
      this.$apiInstance.addQuoteDispatchCustomLine(
        this.$route.params.quoteUUID,
        this.currentDispatchSupplier,
        addQuoteDispatchCustomLineBody
      ).then((data) => {
        this.$notification.notify('SUCCESS',this.$t('AddedLineToDispatch'));
        this.addDispatchCustomLineDialog = false;
      }, (error) => {
        this.$notification.notify('DANGER', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getQuote();
      });
    },
    modifyQuoteCustomLine(customLineUUID, modifiedCustomLine) {
      const modifyQuoteCustomLineBody = new this.$BcmModel.ModifyQuoteCustomLineBody(
        modifiedCustomLine.description,
        Number(modifiedCustomLine.quantity),
        modifiedCustomLine.optional,
        modifiedCustomLine.selected,
        {
          price: Number(modifiedCustomLine.selling.price),
          vatPercentage: Number(modifiedCustomLine.selling.vatPercentage)
        }
      );

      this.$apiInstance.modifyQuoteCustomLine(
        this.$route.params.quoteUUID,
        customLineUUID,
        modifyQuoteCustomLineBody
      ).then((data) => {
        this.$notification.notify('SUCCESS',this.$t('ModifiedLine'));
        this.modifyQuoteCustomLineDialog = false;
      }, (error) => {
        this.$notification.notify('DANGER', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getQuote();
      });
    },
    modifyDispatchCustomLine(customLineUUID, modifiedCustomLine) {
      const modifyQuoteDispatchCustomLineBody = new this.$BcmModel.ModifyQuoteDispatchCustomLineBody(
        modifiedCustomLine.description,
        Number(modifiedCustomLine.quantity),
        modifiedCustomLine.optional,
        modifiedCustomLine.selected,
        {
          price: Number(modifiedCustomLine.purchase.price),
          vatPercentage: Number(modifiedCustomLine.purchase.vatPercentage)
        },
        {
          price: Number(modifiedCustomLine.selling.price),
          vatPercentage: Number(modifiedCustomLine.selling.vatPercentage)
        }
      );

      this.$apiInstance.modifyQuoteDispatchCustomLine(
        this.$route.params.quoteUUID,
        this.currentDispatchSupplier,
        customLineUUID,
        modifyQuoteDispatchCustomLineBody
      ).then((data) => {
        this.$notification.notify('SUCCESS',this.$t('ModifiedLine'));
        this.modifyDispatchCustomLineDialog = false;
      }, (error) => {
        this.$notification.notify('DANGER', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getQuote();
      });
    },
    modifyQuoteCustomLineSelected(customLineUUID, selected) {
      const modifyAdminQuoteCustomLineSelectedBody = new this.$BcmModel.ModifyAdminQuoteCustomLineSelectedBody(
        selected
      );

      this.$apiInstance.modifyAdminQuoteCustomLineSelected(
        this.$route.params.quoteUUID,
        customLineUUID,
        modifyAdminQuoteCustomLineSelectedBody
      ).then((data) => {
        this.$notification.notify('SUCCESS',this.$t('ModifiedLine'));
      }, (error) => {
        this.$notification.notify('DANGER', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getQuote();
      });
    },
    modifyDispatchCustomLineSelected(supplierUUID, customLineUUID, selected) {
      const modifyAdminQuoteDispatchCustomLineSelectedBody = new this.$BcmModel.ModifyAdminQuoteDispatchCustomLineSelectedBody(
        selected
      );

      this.$apiInstance.modifyAdminQuoteDispatchCustomLineSelected(
        this.$route.params.quoteUUID,
        supplierUUID,
        customLineUUID,
        modifyAdminQuoteDispatchCustomLineSelectedBody
      ).then((data) => {
        this.$notification.notify('SUCCESS',this.$t('ModifiedLine'));
      }, (error) => {
        this.$notification.notify('DANGER', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getQuote();
      });
    },
    deleteQuoteCustomLine(customLineUUID) {
      this.$apiInstance.deleteQuoteCustomLine(
        this.$route.params.quoteUUID,
        customLineUUID
      ).then((data) => {
        this.$notification.notify('SUCCESS',this.$t('DeleteLine'));
        this.modifyQuoteCustomLineDialog = false;
      }, (error) => {
        this.$notification.notify('DANGER', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getQuote();
      });
    },
    deleteDispatchCustomLine(customLineUUID) {
      this.$apiInstance.deleteQuoteDispatchCustomLine(
        this.$route.params.quoteUUID,
        this.currentDispatchSupplier,
        customLineUUID
      ).then((data) => {
        this.$notification.notify('SUCCESS',this.$t('DeleteLine'));
        this.modifyDispatchCustomLineDialog = false;
      }, (error) => {
        this.$notification.notify('DANGER', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getQuote();
      });
    },
  },
};
</script>
