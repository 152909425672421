var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-quote mb-3"
  }, [_vm.quote ? _c('div', {
    staticClass: "page-quote-screen"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', [_c('span', {
    staticClass: "text-wrap display-1"
  }, [_vm._v(" " + _vm._s(_vm.$t("Quote")) + " - " + _vm._s(_vm.quote.quoteNumber) + " ")])]), _c('v-col', {
    attrs: {
      "align": "end"
    }
  }, [_c('a', {
    ref: "quotePDFRef",
    staticClass: "d-none",
    attrs: {
      "download": `${_vm.$t('Quote')}_CMT_${_vm.quote.quoteNumber}`
    }
  }), _c('v-btn', {
    staticClass: "text-none white--text mr-2",
    attrs: {
      "color": "orange",
      "loading": _vm.loadingQuotePDF,
      "ripple": false
    },
    on: {
      "click": _vm.getQuotePDF
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-file-pdf ")]), _vm._v(" " + _vm._s(_vm.$t('exportQuote')) + " ")], 1), _vm.quote.status === 'LOCKED' ? [_c('v-icon', {
    staticClass: "mr-1 info--text",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-lock-open ")]), _c('v-icon', {
    staticClass: "mr-1 warning--text",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-exclamation-triangle ")]), _vm._v(" " + _vm._s(_vm.$t("Todo")) + " ")] : _vm.quote.status === 'READY' ? [_c('v-icon', {
    staticClass: "mr-1 info--text",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-lock ")]), _vm._v(" " + _vm._s(_vm.$t("Waiting")) + " ")] : _vm.quote.status === 'ACCEPTED' ? [_c('v-icon', {
    staticClass: "mr-1 info--text",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-lock ")]), _c('v-icon', {
    staticClass: "mr-1 success--text",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" far fa-check-circle ")]), _vm._v(" " + _vm._s(_vm.$t("QuoteAccepted")) + " ")] : _vm.quote.status === 'DECLINED' ? [_c('v-icon', {
    staticClass: "mr-1 info--text",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-lock ")]), _c('v-icon', {
    staticClass: "mr-1 error--text",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" far fa-times-circle ")]), _vm._v(" " + _vm._s(_vm.$t("QuoteDeclined")) + " ")] : _vm._e()], 2)], 1), _c('v-divider', {
    staticClass: "my-5"
  }), _c('v-row', {
    staticClass: "flex-wrap-reverse justify-space-around"
  }, [_c('v-col', [_vm.dispatchLoaded ? _c('BeelseQuote', {
    attrs: {
      "quote": _vm.quote,
      "admin-mode": true,
      "editable": _vm.quote.status === 'LOCKED',
      "edit-send-event": true,
      "suppliers": _vm.suppliers
    },
    on: {
      "modifyDispatchDeliveryPricingType": _vm.modifyQuoteDispatchDeliveryPricingType,
      "modifyDispatchPublicNote": _vm.modifyQuoteDispatchPublicNote,
      "modifyDispatchDeliveryNote": _vm.modifyQuoteDispatchDeliveryNote,
      "modifyPublicNote": _vm.modifyQuotePublicNote,
      "addQuoteCustomLine": _vm.openAddQuoteCustomLineDialog,
      "addDispatchCustomLine": _vm.openAddDispatchCustomLineDialog,
      "modifyQuoteCustomLine": _vm.openModifyQuoteCustomLineDialog,
      "modifyQuoteCustomLineSelected": _vm.modifyQuoteCustomLineSelected,
      "modifyDispatchCustomLine": _vm.openModifyDispatchCustomLineDialog,
      "modifyDispatchCustomLineSelected": _vm.modifyDispatchCustomLineSelected,
      "modifyDispatchItem": _vm.openModifyDispatchItemDialog,
      "modifyDispatchDelivery": _vm.openModifyDispatchDeliveryDialog
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm.quote ? _c('QuoteValidation', {
    attrs: {
      "quote": _vm.quote
    },
    on: {
      "modifyQuoteStatus": _vm.modifyQuoteStatus,
      "modifyQuoteExpirationDate": _vm.modifyQuoteExpirationDate
    }
  }) : _vm._e()], 1)], 1), _c('v-card', {
    staticClass: "col-md-6 col-12 ma-auto mt-2"
  }, [_c('v-card-text', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_c('h5', {
    staticClass: "subtitle-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("InternalNote")) + " ")]), _c('p', {
    staticClass: "body-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("PartInternalNoteNotVisibleMention")) + " ")])]), _c('v-spacer'), _c('v-card-actions', [!_vm.editNote ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "orange",
      "ripple": false,
      "text": "",
      "title": _vm.$t('Edit')
    },
    on: {
      "click": function ($event) {
        _vm.editNote = !_vm.editNote;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-edit ")]), _vm._v(" " + _vm._s(_vm.$t("Edit")) + " ")], 1) : _c('v-icon', {
    attrs: {
      "small": "",
      "color": "orange",
      "title": _vm.$t('Cancel')
    },
    on: {
      "click": _vm.cancelEditNote
    }
  }, [_vm._v(" fas fa-times ")])], 1)], 1), _c('div', {
    staticClass: "caption pl-4"
  }, [!_vm.editNote ? _c('pre', [_vm._v(_vm._s(_vm.quoteInternalNote))]) : [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "auto-grow": true,
      "placeholder": `${_vm.$t('InternalNotePlaceholder')}`
    },
    model: {
      value: _vm.quoteInternalNote,
      callback: function ($$v) {
        _vm.quoteInternalNote = $$v;
      },
      expression: "quoteInternalNote"
    }
  }, [_vm._v(" " + _vm._s(_vm.quoteInternalNote) + " ")]), _c('div', {
    staticClass: "d-flex flex-row-reverse"
  }, [_c('v-btn', {
    staticClass: "text-none ml-2",
    attrs: {
      "color": "success"
    },
    on: {
      "click": _vm.modifyEditNote
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fa fa-check ")]), _vm._v(" " + _vm._s(_vm.$t("Save")) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.cancelEditNote
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fa fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")], 1)], 1)]], 2)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "1400px"
    },
    model: {
      value: _vm.addDispatchCustomLineDialog,
      callback: function ($$v) {
        _vm.addDispatchCustomLineDialog = $$v;
      },
      expression: "addDispatchCustomLineDialog"
    }
  }, [_c('AddCustomLine', {
    attrs: {
      "display-close-buttons": true,
      "specify-purchase-price": true,
      "specify-selling-price": true
    },
    on: {
      "addCustomLine": _vm.addDispatchCustomLine,
      "close": _vm.closeAddDispatchCustomLineDialog
    }
  })], 1), _c('v-dialog', {
    attrs: {
      "max-width": "1400px"
    },
    model: {
      value: _vm.addQuoteCustomLineDialog,
      callback: function ($$v) {
        _vm.addQuoteCustomLineDialog = $$v;
      },
      expression: "addQuoteCustomLineDialog"
    }
  }, [_c('AddCustomLine', {
    attrs: {
      "display-close-buttons": true,
      "specify-purchase-price": false,
      "specify-selling-price": true
    },
    on: {
      "addCustomLine": _vm.addQuoteCustomLine,
      "close": _vm.closeAddQuoteCustomLineDialog
    }
  })], 1), _c('v-dialog', {
    attrs: {
      "max-width": "1400px"
    },
    model: {
      value: _vm.modifyDispatchItemDialog,
      callback: function ($$v) {
        _vm.modifyDispatchItemDialog = $$v;
      },
      expression: "modifyDispatchItemDialog"
    }
  }, [_vm.dispatchItemToModify ? _c('ModifyItemLine', {
    attrs: {
      "item": _vm.dispatchItemToModify,
      "display-close-buttons": true,
      "specify-purchase-price": true,
      "specify-selling-price": true
    },
    on: {
      "close": _vm.closeModifyDispatchItemDialog,
      "modifyItemLine": _vm.modifyQuoteDispatchItemPrices
    }
  }) : _vm._e()], 1), _c('v-dialog', {
    attrs: {
      "max-width": "1400px"
    },
    model: {
      value: _vm.modifyDispatchCustomLineDialog,
      callback: function ($$v) {
        _vm.modifyDispatchCustomLineDialog = $$v;
      },
      expression: "modifyDispatchCustomLineDialog"
    }
  }, [_vm.customLineToModify ? _c('ModifyCustomLine', {
    attrs: {
      "custom-line": _vm.customLineToModify,
      "display-close-buttons": true,
      "specify-purchase-price": true,
      "specify-selling-price": true
    },
    on: {
      "close": _vm.closeModifyDispatchCustomLineDialog,
      "modifyCustomLine": _vm.modifyDispatchCustomLine,
      "deleteCustomLine": _vm.deleteDispatchCustomLine
    }
  }) : _vm._e()], 1), _c('v-dialog', {
    attrs: {
      "max-width": "1100px"
    },
    model: {
      value: _vm.modifyDispatchDeliveryDialog,
      callback: function ($$v) {
        _vm.modifyDispatchDeliveryDialog = $$v;
      },
      expression: "modifyDispatchDeliveryDialog"
    }
  }, [_vm.dispatchDeliveryToModify ? _c('ModifyDelivery', {
    attrs: {
      "dispatch": _vm.dispatchDeliveryToModify,
      "display-close-buttons": true,
      "specify-purchase-price": true,
      "specify-selling-price": true
    },
    on: {
      "close": _vm.closeModifyDispatchDeliveryDialog,
      "modifyDelivery": _vm.modifyQuoteDispatchDelivery
    }
  }) : _vm._e()], 1), _c('v-dialog', {
    attrs: {
      "max-width": "1400px"
    },
    model: {
      value: _vm.modifyQuoteCustomLineDialog,
      callback: function ($$v) {
        _vm.modifyQuoteCustomLineDialog = $$v;
      },
      expression: "modifyQuoteCustomLineDialog"
    }
  }, [_vm.customLineToModify && _vm.modifyQuoteCustomLineDialog ? _c('ModifyCustomLine', {
    attrs: {
      "custom-line": _vm.customLineToModify,
      "display-close-buttons": true,
      "specify-purchase-price": false,
      "specify-selling-price": true
    },
    on: {
      "close": _vm.closeModifyQuoteCustomLineDialog,
      "modifyCustomLine": _vm.modifyQuoteCustomLine,
      "deleteCustomLine": _vm.deleteQuoteCustomLine
    }
  }) : _vm._e()], 1)], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }