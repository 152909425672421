var render = function render(){
  var _vm$quote$closedBy, _vm$quote$closedBy2, _vm$quote$closedBy3, _vm$quote$closedBy4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "component-quoteValidation"
  }, [_c('v-card', [_c('v-card-text', [_c('div', {
    staticClass: "text-h6 black--text mb-4 d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "info",
      "small": ""
    }
  }, [_vm._v(" fas fa-file-signature ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('Quote')) + " - " + _vm._s(_vm.quote.quoteNumber) + " ")])], 1), _vm.isBeingConfigured ? _c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('tbody', [_c('tr', [_c('td', {
          staticClass: "font-weight-medium",
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('Expiration')) + " ")]), _c('td', [_c('v-menu', {
          attrs: {
            "close-on-content-click": false,
            "offset-x": "",
            "left": "",
            "min-width": "auto",
            "color": "info"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var on = _ref.on,
                attrs = _ref.attrs;
              return [_c('v-text-field', _vm._g(_vm._b({
                attrs: {
                  "value": _vm.expirationDate ? _vm.$d(new Date(_vm.expirationDate), 'short', _vm.$userLocale) : null,
                  "label": _vm.expirationDate ? _vm.$t('WillBeInvalidOn') : _vm.$t('NoExpirationDate'),
                  "append-icon": "fas fa-calendar-alt",
                  "readonly": "",
                  "dense": "",
                  "hide-details": "auto",
                  "outlined": ""
                }
              }, 'v-text-field', attrs, false), on))];
            }
          }], null, false, 1316231657),
          model: {
            value: _vm.menu,
            callback: function ($$v) {
              _vm.menu = $$v;
            },
            expression: "menu"
          }
        }, [_c('v-date-picker', {
          attrs: {
            "min": new Date().toISOString(),
            "no-title": "",
            "scrollable": "",
            "color": "info",
            "clearable": "",
            "show-current": false
          },
          on: {
            "input": _vm.verifyDateInput
          },
          model: {
            value: _vm.expirationDate,
            callback: function ($$v) {
              _vm.expirationDate = $$v;
            },
            expression: "expirationDate"
          }
        }, [_c('v-btn', {
          staticClass: "text-none",
          attrs: {
            "disabled": _vm.loading,
            "small": "",
            "color": "error"
          },
          on: {
            "click": _vm.init
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-times-circle ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")])], 1), _c('v-spacer'), _c('v-btn', {
          staticClass: "text-none",
          attrs: {
            "loading": _vm.loading,
            "small": "",
            "color": "info"
          },
          on: {
            "click": _vm.modifyQuoteExpirationDate
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-save ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('Save')) + " ")])], 1)], 1)], 1)], 1)])])];
      },
      proxy: true
    }], null, false, 1242629751)
  }) : _vm._e(), _c('v-divider'), _c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('tbody', [_c('tr', [_c('td', {
          staticClass: "col-item-price-ht font-weight-medium",
          attrs: {
            "width": "70%"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('NetTotalVATExcluded')) + " ")]), _c('td', {
          staticClass: "col-item-total-ht text-right"
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$n(_vm.quote.selling.totalAmountWithoutVAT, "currency", "fr")) + " ")])])]), _c('tr', [_c('td', {
          staticClass: "pl-8 text-caption custom-dense-td"
        }, [_vm._v(" " + _vm._s(_vm.$t('IncludingDeliveryOf')) + " ")]), _c('td', {
          staticClass: "custom-dense-td text-right"
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$n(_vm.quote.selling.deliveryPrice, "currency", "fr")) + " ")])])]), _vm.quote.selling.totalDiscount !== '0' ? _c('tr', [_c('td', {
          staticClass: "pl-8 text-caption custom-dense-td"
        }, [_vm._v(" " + _vm._s(_vm.$t('IncludingDiscountOf')) + " ")]), _c('td', {
          staticClass: "custom-dense-td text-right"
        }, [_vm._v(" " + _vm._s(_vm.$n(_vm.quote.selling.totalDiscount, "currency", "fr")) + " ")])]) : _vm._e(), _c('tr', [_c('td', {
          staticClass: "font-weight-medium"
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('TotalVAT')) + " ")])]), _c('td', {
          staticClass: "text-right"
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$n(_vm.quote.selling.vatTotal, "currency", "fr")) + " ")])])]), _c('tr', [_c('td', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm.$t('TotalAmountVATIncluded')) + " ")]), _c('td', {
          staticClass: "text-right text-h6"
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$n(_vm.quote.selling.totalAmount, "currency", "fr")) + " ")])])])])];
      },
      proxy: true
    }])
  }), _c('v-divider', {
    staticClass: "mb-4"
  }), _vm.quote.status === 'LOCKED' && _vm.tooMuchDeliveryDiscount ? _c('v-alert', {
    attrs: {
      "type": "warning",
      "text": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('VerifyDeliveryDiscount')) + " ")]) : _vm._e(), _vm.quote.status === 'READY' ? _c('v-alert', {
    attrs: {
      "type": "info",
      "text": ""
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('QuoteAwaitingCustomerValidation')) + " ")])]) : _vm.quote.status === 'LOCKED' ? _c('v-alert', {
    attrs: {
      "type": "info",
      "text": "",
      "icon": "fas fa-lock"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('QuoteIsBeingConfigured')) + " ")])]) : _vm.quote.status === 'ACCEPTED' || _vm.quote.status === 'CLOSED' ? _c('v-alert', {
    attrs: {
      "type": "success",
      "text": ""
    }
  }, [_c('div', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('QuoteAcceptedOn')) + " ")]), _c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.$d(new Date(_vm.quote.closedDate), 'numeric')) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('By').toLowerCase()) + " ")]), _c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v(" " + _vm._s((_vm$quote$closedBy = _vm.quote.closedBy) === null || _vm$quote$closedBy === void 0 ? void 0 : _vm$quote$closedBy.firstname) + " " + _vm._s((_vm$quote$closedBy2 = _vm.quote.closedBy) === null || _vm$quote$closedBy2 === void 0 ? void 0 : _vm$quote$closedBy2.lastname) + " ")])]), _c('div', {
    staticClass: "mt-2"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "color": "info"
    }
  }, [_vm._v(" fas fa-box ")]), _c('router-link', {
    attrs: {
      "to": {
        name: 'Order',
        params: {
          supplierUUID: _vm.$route.params.supplierUUID,
          orderUUID: _vm.quote.order
        }
      }
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('ViewOrder')) + " ")])])], 1)]) : _vm.quote.status === 'DECLINED' ? _c('v-alert', {
    attrs: {
      "type": "error",
      "icon": "fas fa-times-circle",
      "text": ""
    }
  }, [_c('div', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('QuoteDeclinedOn')) + " ")]), _c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.$d(new Date(_vm.quote.closedDate), 'numeric')) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('By').toLowerCase()) + " ")]), _c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v(" " + _vm._s((_vm$quote$closedBy3 = _vm.quote.closedBy) === null || _vm$quote$closedBy3 === void 0 ? void 0 : _vm$quote$closedBy3.firstname) + " " + _vm._s((_vm$quote$closedBy4 = _vm.quote.closedBy) === null || _vm$quote$closedBy4 === void 0 ? void 0 : _vm$quote$closedBy4.lastname) + " ")])])]) : _vm._e(), _vm.quote.status === 'READY' ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "loading": _vm.loading,
      "color": "info",
      "width": "100%"
    },
    on: {
      "click": function ($event) {
        return _vm.modifyQuoteStatus('LOCKED');
      }
    }
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('ModifyQuote')) + " ")]), _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-pen ")])], 1) : _vm._e(), _vm.quote.status === 'LOCKED' ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "loading": _vm.loading,
      "disabled": !_vm.canBeSentToCustomer,
      "color": "success",
      "width": "100%"
    },
    on: {
      "click": function ($event) {
        return _vm.modifyQuoteStatus('READY');
      }
    }
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('SendQuoteToCustomer')) + " ")]), _c('v-icon', [_vm._v(" fas fa-paper-plane ")])], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }