<template lang="html" src="./quoteValidation.template.vue"></template>

<style lang="scss" src="./quoteValidation.scss"></style>

<script>

const i18nData = require('./quoteValidation.i18n');

export default {
  name: 'QuoteValidation',
  components: {
  },
  props: {
    quote: {
      type: Object,
      required: true,
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  watch: {
    quote: {
      handler: function() {
        this.init();
      },
      deep: true
    }
  },
  data() {
    return {
      expirationDate: null,
      previouslyPickedExpirationDate: null,
      loading: false,
      menu: false
    };
  },
  computed: {
    tooMuchDeliveryDiscount() {
      return this.quote.customLines.filter(cL => {
        return cL.description === 'Remise frais de livraison';
      }).length > this.quote.dispatch.length;
    },
    isBeingConfigured() {
      return this.quote.status === 'LOCKED' || this.quote.status === 'READY';
    },
    canBeSentToCustomer() {
      const validStatus = ['HOMOLOGATION', 'HOMOLOGATED'];
      const validHomologationStatus = ['READY', 'ORDERED', 'TESTING'];
      return this.quote.dispatch.every(d => {
        return d.status === 'READY'
          && d.items.every(i => {
            return i.selling.price !== null && validStatus.includes(i.part.status) && validHomologationStatus.includes(i.part.homologation?.status);
          });
      })
        && this.quote.status === 'LOCKED'
        && this.quote.unconfiguredItems.length === 0;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.expirationDate = this.quote.expirationDate ? this.quote.expirationDate.toISOString().split('T')[0] : null;
      this.menu = false;
      this.loading = false;
    },
    verifyDateInput() {
      if(this.previouslyPickedExpirationDate === this.expirationDate) {
        // Double click on same date = reset it
        this.expirationDate = null;
      }
      this.previouslyPickedExpirationDate = this.expirationDate;
    },
    modifyQuoteStatus(status) {
      this.loading = true;
      this.$emit('modifyQuoteStatus', status);
    },
    modifyQuoteExpirationDate() {
      this.loading = true;
      this.$emit('modifyQuoteExpirationDate', this.expirationDate ? new Date(this.expirationDate).toISOString() : null);
    }
  }
};
</script>
