var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "component-dispatchValidation"
  }, [_c('v-expansion-panels', {
    attrs: {
      "value": _vm.model,
      "readonly": "",
      "focusable": ""
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', {
    staticClass: "pointer-event-none d-inline-block",
    attrs: {
      "hide-actions": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "color": "info",
            "small": ""
          }
        }, [_vm._v(" fas fa-truck ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('Dispatch')) + " ")]), _c('v-icon', {
          staticClass: "mx-3",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-arrow-right ")]), _c('span', [_vm._v(" " + _vm._s(_vm.supplier.name) + " ")])];
      },
      proxy: true
    }])
  }), _c('v-expansion-panel-content', {
    staticClass: "pt-4"
  }, [_c('v-btn-toggle', {
    staticClass: "fill-width mb-2",
    attrs: {
      "mandatory": "",
      "color": "info"
    },
    model: {
      value: _vm.displayMode,
      callback: function ($$v) {
        _vm.displayMode = $$v;
      },
      expression: "displayMode"
    }
  }, [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "width": "50%"
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-city ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('PurchasePrice')) + " ")])], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "width": "50%"
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-warehouse ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('SellingPrice')) + " ")])], 1)], 1), _vm.displayMode === 0 ? _c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('tbody', [_c('tr', [_c('td', {
          staticClass: "col-item-price-ht font-weight-medium",
          attrs: {
            "width": "70%"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('NetTotalVATExcluded')) + " ")]), _c('td', {
          staticClass: "col-item-total-ht text-right"
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$n(_vm.dispatch.purchase.totalAmountWithoutVAT, "currency", "fr")) + " ")])])]), _c('tr', [_c('td', {
          staticClass: "pl-8 text-caption custom-dense-td"
        }, [_vm._v(" " + _vm._s(_vm.$t('IncludingDeliveryOf')) + " ")]), _c('td', {
          staticClass: "custom-dense-td text-right"
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$n(_vm.dispatch.purchase.deliveryPrice, "currency", "fr")) + " ")])])]), _vm.dispatch.purchase.totalDiscount !== '0' ? _c('tr', [_c('td', {
          staticClass: "pl-8 text-caption custom-dense-td"
        }, [_vm._v(" " + _vm._s(_vm.$t('IncludingDiscountOf')) + " ")]), _c('td', {
          staticClass: "custom-dense-td text-right"
        }, [_vm._v(" " + _vm._s(_vm.$n(_vm.dispatch.purchase.totalDiscount, "currency", "fr")) + " ")])]) : _vm._e(), _c('tr', [_c('td', {
          staticClass: "font-weight-medium"
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('TotalVAT')) + " ")])]), _c('td', {
          staticClass: "text-right"
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$n(_vm.dispatch.purchase.vatTotal, "currency", "fr")) + " ")])])]), _c('tr', [_c('td', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm.$t('TotalAmountVATIncluded')) + " ")]), _c('td', {
          staticClass: "text-right text-h6"
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$n(_vm.dispatch.purchase.totalAmount, "currency", "fr")) + " ")])])])])];
      },
      proxy: true
    }], null, false, 154115371)
  }) : _vm._e(), _vm.displayMode === 1 ? _c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('tbody', [_c('tr', [_c('td', {
          staticClass: "col-item-price-ht font-weight-medium",
          attrs: {
            "width": "70%"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('ItemsAmountVATExcluded')) + " ")]), _c('td', {
          staticClass: "col-item-total-ht text-right"
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$n(_vm.dispatch.selling.totalItemsAmount, "currency", "fr")) + " ")])])]), _c('tr', [_c('td', {
          staticClass: "pl-8 text-caption custom-dense-td"
        }, [_vm._v(" " + _vm._s(_vm.$t('DeliveryVATExcluded')) + " ")]), _c('td', {
          staticClass: "custom-dense-td text-right"
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$n(_vm.dispatch.selling.deliveryPrice, "currency", "fr")) + " ")])])]), _c('tr', [_c('td', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm.$t('DiscountVATExcluded')) + " ")]), _c('td', {
          staticClass: "custom-dense-td text-right"
        }, [_vm._v(" " + _vm._s(_vm.$n(_vm.dispatch.selling.totalDiscount, "currency", "fr")) + " ")])]), _c('tr', [_c('td', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm.$t('NetTotalVATExcluded')) + " ")]), _c('td', {
          staticClass: "text-right text-h6"
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$n(_vm.dispatch.selling.totalAmountWithoutVAT, "currency", "fr")) + " ")])])])])];
      },
      proxy: true
    }], null, false, 1587547687)
  }) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }